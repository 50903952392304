import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import SEO from 'components/shared/SEO';
import Header from 'components/Header/Header';

const NotFound = ({ data }) => {
  const heroImg = data.heroImg.childImageSharp.fluid;
  const slides = [`<b>Nie znaleziono<br/>strony</b>`];

  return (
    <>
      <SEO title="Nie znaleziono" />
      <Header notFound image={heroImg} imageAlt="test" slides={slides} />
    </>
  );
};

export const query = graphql`
  {
    heroImg: file(name: { eq: "hero-img" }) {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

NotFound.propTypes = {
  data: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default NotFound;

import React, { useState, useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import LazyImage from 'components/shared/LazyImage';
import Icon from 'components/shared/Icon';
import Button from 'components/shared/Button';
import scrollToSection from 'utils/scrollToSection';
import headerShape from 'assets/images/header-shape.png';
import header2Shape from 'assets/images/about-shape.png';
import logoIcon from 'assets/icons/sap_logo.svg';

const InnerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  padding-left: 25px;
  ${({ theme }) => theme.mq.xs} {
    top: 40%;
    transform: translate(0, -40%);
  }
  ${({ theme }) => theme.mq.md} {
    padding-left: 100px;
  }
  ${({ theme }) => theme.mq.xl} {
    left: 15%;
    padding-left: 15px;
    transform: translate(-15%, -40%);
  }
`;

const Wrapper = styled.header`
  position: relative;
  width: 100%;
  height: 100vh;
  min-height: 650px;
  &:before {
    content: '';
    position: absolute;
    bottom: 20px;
    right: -5px;
    width: 140px;
    height: 131px;
    background: url(${headerShape}) no-repeat center;
    background-size: 100%;
    ${({ theme }) => theme.mq.xxl} {
      top: 120px;
      bottom: auto;
      width: 179px;
      height: 171px;
      right: -30px;
      z-index: 2;
    }
  }
  ${({ theme }) => theme.mq.xs} {
    height: 100%;
  }
  ${({ full }) =>
    full &&
    css`
      height: calc(100vh - 148px) !important;
      overflow: hidden !important;
      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 540px;
        left: 0;
        width: 226px;
        height: 69px;
        background: url(${header2Shape}) no-repeat center;
        background-size: 100%;
        ${({ theme }) => theme.mq.lg} {
          display: none;
        }
      }
      ${({ theme }) => theme.mq.s} {
        height: calc(100vh - 75px) !important;
      }
      & > ${InnerWrapper} {
        top: 50% !important;
        left: 15% !important;
        padding-left: 25px !important;
        transform: translate(-15%, -50%) !important;
      }
    `}
`;

const TitleWrapper = styled.div`
  position: relative;
  display: flex;
  ${({ auto }) =>
    !auto &&
    css`
      width: 300px;
      height: 140px;
      ${({ theme }) => theme.mq.xs} {
        width: 400px;
      }
      ${({ theme }) => theme.mq.s} {
        width: 600px;
        height: 160px;
      }
      ${({ theme }) => theme.mq.lg} {
        width: 750px;
      }
      ${({ theme }) => theme.mq.xl} {
        height: 210px;
      }
    `}
`;

const Slide = styled.div`
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transform: translateX(100px);
  transition: 0.7s;
  ${({ isActive }) =>
    isActive &&
    css`
      opacity: 1;
      visibility: visible;
      transform: translateX(0);
      transition-delay: 0.4s;
    `}
`;

const Title = styled.h1`
  font-size: 2.6rem;
  line-height: 3.4rem;
  font-weight: ${({ theme }) => theme.light};
  ${({ theme }) => theme.mq.xs} {
    font-size: 3rem;
    line-height: 4rem;
  }
  ${({ theme }) => theme.mq.s} {
    font-size: ${({ theme }) => theme.fontSize.xlg};
    line-height: 4.6rem;
  }
  ${({ theme }) => theme.mq.lg} {
    font-size: 4.2rem;
    line-height: 5rem;
  }
  ${({ theme }) => theme.mq.xl} {
    font-size: 5rem;
    line-height: 6rem;
  }
`;

const ImageWrapper = styled.div`
  display: none;
  transform: translateX(200px);
  pointer-events: none;
  user-select: none;
  ${({ theme }) => theme.mq.xs} {
    display: block;
  }
  ${({ theme }) => theme.mq.xxl} {
    transform: translateX(0);
  }
`;

const ControlPanel = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  top: 100px;
  left: 15px;
  z-index: 5;
  ${({ theme }) => theme.mq.md} {
    left: 35px;
    flex-direction: column;
    top: 32%;
    transform: translateY(-32%);
  }
`;

const ControlText = styled.span`
  display: none;
  font-size: ${({ theme }) => theme.fontSize.md};
  font-weight: ${({ theme }) => theme.medium};
  margin: 20px 0;
  ${({ theme }) => theme.mq.md} {
    display: block;
  }
`;

const ControlButton = styled.button`
  width: 15px;
  height: 15px;
  margin: 0 10px;
  transform: skew(-15deg);
  cursor: pointer;
  transition: 0.6s;
  background-color: ${({ theme, isActive }) =>
    isActive ? theme.colors.purple : theme.colors.gray300};
  ${({ theme }) => theme.mq.md} {
    margin: 15px 0;
  }
`;

const LogoSAP = styled.div`
  position: absolute;
  display: none;
  align-items: center;
  justify-content: center;
  top: 130px;
  left: 55%;
  height: 75px;
  z-index: 20;

  & > div {
    width: 100%;
    height: 100%;
  }

  ${({ theme }) => theme.mq.xl} {
    display: flex;
  }

  ${({ theme }) => theme.mq.xxl} {
    top: 140px;
    left: 42%;
  }

  ${({ theme }) => theme.mq.xxxl} {
    top: 170px;
    height: 85px;
  }
`;

const Header = ({ image, imageAlt, slides, notFound, buttonText }) => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const timeoutRef = useRef(null);

  const slide = useCallback(() => {
    const nextSlide = (activeSlide + 1) % slides.length;
    setActiveSlide(nextSlide);
  }, [activeSlide]);

  const changeToSlide = slideIndex => {
    if (!isAnimating) setActiveSlide(slideIndex);
  };

  useEffect(() => {
    const setSlideTimeout = () => {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(slide, 5000);

      setIsAnimating(true);
      setTimeout(() => setIsAnimating(false), 1000);
    };

    setSlideTimeout();

    return () => {
      clearTimeout(timeoutRef.current);
      clearTimeout(setSlideTimeout);
    };
  }, [activeSlide, slide]);
  return (
    <Wrapper full={notFound}>
      {slides.length > 1 && (
        <ControlPanel>
          <ControlText>{`0${activeSlide + 1}`}</ControlText>
          {slides.map((_, index) => (
            <ControlButton
              key={index}
              onClick={() => changeToSlide(index)}
              isActive={index === activeSlide}
              aria-label={`${index + 1} slide`}
            />
          ))}
          <ControlText>{`0${slides.length}`}</ControlText>
        </ControlPanel>
      )}
      <ImageWrapper>
        <LazyImage minHeight="650px" src={image} alt={imageAlt} cover />
      </ImageWrapper>
      <InnerWrapper>
        <TitleWrapper auto={notFound}>
          {slides.length > 1 ? (
            slides.map((title, index) => (
              <Slide isActive={activeSlide === index} key={title}>
                <Title dangerouslySetInnerHTML={{ __html: title }} />
              </Slide>
            ))
          ) : (
            <Title dangerouslySetInnerHTML={{ __html: slides[0] }} />
          )}
        </TitleWrapper>
        {slides.length > 1 && (
          <Button onClick={() => scrollToSection('#contact')} next>
            {buttonText}
          </Button>
        )}
        {notFound && (
          <Button big as={Link} to="/" next>
            Strona główna
          </Button>
        )}
      </InnerWrapper>
      <LogoSAP>
        <Icon src={logoIcon} alt="logo" />
      </LogoSAP>
    </Wrapper>
  );
};

Header.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.string).isRequired,
  image: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  imageAlt: PropTypes.string.isRequired,
  notFound: PropTypes.bool,
  buttonText: PropTypes.string,
};

Header.defaultProps = {
  notFound: false,
  buttonText: 'Zapytaj o ofertę',
};

export default Header;

import styled, { css, keyframes } from 'styled-components';
import nextIcon from 'assets/icons/next.svg';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px;
  background: ${({ theme }) => theme.gradients.purple};
  background-size: 100% auto;
  background-position: center;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.mainFont};
  font-size: ${({ theme }) => theme.fontSize.m};
  font-weight: ${({ theme }) => theme.light};
  width: 100%;
  max-width: 220px;
  cursor: pointer;
  text-decoration: none;
  transition: 0.5s;
  text-align: center;
  border-radius: 50px;
  ${({ theme }) => theme.mq.s} {
    font-size: ${({ theme }) => theme.fontSize.md};
    max-width: 300px;
    padding: 22px;
  }
  @media (hover: hover) {
    &:hover {
      background-size: 200% auto;
      background-position: right center;
    }
  }
  ${({ next }) =>
    next &&
    css`
      position: relative;
      ${({ theme }) => theme.mq.md} {
        box-shadow: 10px 20px 40px 30px rgba(120, 61, 171, 0.2);
      }
      @media (hover: hover) {
        &:hover:after {
          transform: translateX(10px);
        }
      }
      &:after {
        content: '';
        position: absolute;
        right: 28px;
        width: 9px;
        height: 17px;
        background: url(${nextIcon}) no-repeat center;
        background-size: 9px 17px;
        transition: 0.3s;
        ${({ theme }) => theme.mq.s} {
          right: 35px;
        }
      }
      ${({ big }) =>
        big &&
        css`
          margin-top: 30px;
          padding: 24px;
          ${({ theme }) => theme.mq.s} {
            padding: 26px;
            margin-top: 50px;
          }
        `}
    `}
  ${({ submit }) =>
    submit &&
    css`
      position: relative;
      background: ${({ theme }) => theme.gradients.purpleBlue};
      transition: 1s;
      height: 73px;
      z-index: 10;
      @media (hover: hover) {
        &:hover {
          background-size: 150% auto;
        }
      }
      &:before {
        content: '';
        position: absolute;
        top: 25px;
        left: 48%;
        width: 24px;
        height: 24px;
        border: 3px solid ${({ theme }) => theme.colors.white};
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
        border-radius: 50%;
        opacity: ${({ disabled }) => (disabled ? '1' : '0')};
        animation: ${spin} 1s ease infinite;
      }
      ${({ disabled }) =>
        disabled &&
        css`
          cursor: default !important;
        `}
    `}
`;

export default Button;
